import './Login/Login.scss';

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Auth } from '../apis/Auth';
import Background from '../components/AuthBackground/AuthBackground';
import { useQuery } from 'core/hooks';

const DEFAULT_VALUE = {
  password: '',
  confirmPassword: ''
};

const ResetPassword = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState(DEFAULT_VALUE);
  const [errors, setErrors] = useState(DEFAULT_VALUE);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState(false);

  const query = useQuery();
  const token = query.get('token');

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const formErrors: any = {};
    let hasErrors = false;

    const { password, confirmPassword } = formData;

    if (!password.trim()) {
      formErrors.password = 'Password is required';
      hasErrors = true;
    }

    if (!confirmPassword.trim()) {
      formErrors.confirmPassword = 'Confirm Password is required';
      hasErrors = true;
    }

    if (!hasErrors) {
      if (password.trim() !== confirmPassword.trim()) {
        formErrors.passwordUnmatched = 'Password Unmatched';
        hasErrors = true;
      }
    }

    if (hasErrors) {
      setErrors(formErrors);
      return;
    }

    const payload = {
      token: token,
      new_password: password
    };

    const data = await Auth.resetPassword(payload);
    if (data) {
      navigate('/login');
    }
  };

  return (
    <div className="relative">
      <Background imageUrl="/auth/authback.png" />

      <div className="main-container h-screen">
        <div className="mt-[20px] align-center flex justify-center">
          <Link to="/login">
            <img width={200} src="/brand-logo/logo.svg" alt="" />
          </Link>
        </div>
        <div className="mt-[20px] align-center flex justify-center">
          <h3 className="AuthHeader Manrope text-white">Forgot Password</h3>
        </div>

        <div className="flex justify-center mt-4">
          <div className="AuthBody flex justify-center">
            <div className="w-[400px]">
              {/* form section */}
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <div className="flex flex-start">
                    <label
                      htmlFor="password"
                      className="Manrope text-white block text-sm font-semibold mb-1 InputLabel mt-[10px]"
                    >
                      Password:
                    </label>
                  </div>
                  <div className="relative">
                    <input
                      value={formData.password}
                      onChange={handleChange}
                      type={passwordVisibility ? 'text' : 'password'}
                      id="password"
                      name="password"
                      className="w-full transparent-input border mt-[5px]  text-white Input"
                    />
                    <img
                      onClick={() => {
                        setPasswordVisibility(!passwordVisibility);
                      }}
                      className="absolute eyeIcon pointer "
                      src="/auth/Icon.png"
                      alt=""
                    />
                  </div>
                  <div className="flex flex-start">
                    {errors.password && (
                      <div className="text-red-500 text-sm mt-1">{errors.password}</div>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <div className="flex flex-start">
                    <label
                      htmlFor="password"
                      className="Manrope text-white block text-sm font-semibold mb-1 InputLabel mt-[10px]"
                    >
                      Confirm Password:
                    </label>
                  </div>
                  <div className="relative">
                    <input
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      type={confirmPasswordVisibility ? 'text' : 'password'}
                      id="confirmPassword"
                      name="confirmPassword"
                      className="w-full transparent-input border mt-[5px]  text-white Input"
                    />
                    <img
                      onClick={() => {
                        setConfirmPasswordVisibility(!confirmPasswordVisibility);
                      }}
                      className="absolute eyeIcon pointer "
                      src="/auth/Icon.png"
                      alt=""
                    />
                  </div>
                  <div className="flex flex-start">
                    {errors.confirmPassword && (
                      <div className="text-red-500 text-sm mt-1">{errors.confirmPassword}</div>
                    )}
                  </div>
                </div>
                <button type="submit" className="submitBtn w-full mt-8">
                  Reset Password
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
