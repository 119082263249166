import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { Auth } from '../apis/Auth';

import CustomModal from './Modal/CustomModal';

function ForgotPasswordModal({ isOpen, onClose }: any) {
  const [formData, setFormData] = useState({ email: '' });
  const [errors, setErrors] = useState({ email: '' });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const formErrors: any = {};
    let hasError = false;

    if (!formData.email.trim()) {
      formErrors.email = 'Email is required';
      hasError = true;
    }

    if (hasError) {
      setErrors(formErrors);
      return;
    }

    const payload = { email: formData.email };

    const data = await Auth.forgotPassword(payload);

    if (data) {
      toast.success(data?.message);
    }
  };

  return (
    <CustomModal
      title="Forgot Password"
      isOpen={isOpen}
      onClose={onClose}
      padding="p-6"
      className="text-white"
    >
      <div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <div className="flex flex-start">
              <label htmlFor="email" className="Manrope text-sm font-semibold mb-1 InputLabel ">
                Email
              </label>
            </div>
            <input
              value={formData.email}
              onChange={handleChange}
              type="email"
              id="email"
              name="email"
              className="w-full bg-slate-700 mt-[5px] text-white Input"
            />
            <div className="flex flex-start">
              {errors.email && <div className="text-red-500 text-sm mt-1">{errors.email}</div>}
            </div>
          </div>

          <button type="submit" className="submitBtn w-full mt-8">
            Send Reset Link
          </button>
        </form>
      </div>
    </CustomModal>
  );
}

export default ForgotPasswordModal;
