import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Ingest } from 'apis/Ingest';
import { toHumanReadableDate } from 'core/helpers';
import { ReaderName, ReadersIcons, extractIngest } from 'core/helpers/ingest';
import PDFPreviewModal from 'components/Utility/PDFViewerModal';

import '@llamaindex/pdf-viewer/index.css';

interface IProps {
  ingest: any;
  getIngestedFiles: any;
}

function IngestRow({ ingest, getIngestedFiles }: IProps) {
  const { projectUid } = useParams();

  const [isOpen, setIsOpen] = useState(false);
  const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);
  const closeModal = () => setIsOpen(false);

  const deleteFile = async () => {
    const data = await Ingest.deleteFile(projectUid, ingest?.id);
    if (data) {
      getIngestedFiles();
      closeModal();
    }
  };

  // const reIngest = async () => {
  //   if (window.confirm('Are you sure want to re ingest data?')) {
  //     const data = await Ingest.reIngest(projectUid, ingest?.id);
  //     if (data) {
  //       getIngestedFiles();
  //       closeModal();
  //     }
  //   }
  // };

  const { fileExt, ingestName, ingestFullPath, createdAt, creator, readerType, status } =
    extractIngest(ingest);

  return (
    <tr className="border-b border-solid border-b-[#222831] last-of-type:border-0">
      <td className="pt-4 pb-3 pl-8">
        {fileExt === 'pdf' ? (
          <PDFPreviewModal
            fileName={ingestName}
            fileUrl={ingestFullPath}
            isOpen={isPDFModalOpen}
            onClick={() => setIsPDFModalOpen(!isPDFModalOpen)}
          >
            <div
              className="flex items-center gap-4 cursor-pointer"
              onClick={() => setIsPDFModalOpen(!isPDFModalOpen)}
            >
              <img
                width="24"
                src={ReadersIcons[readerType]}
                className="rounded-md"
                id="menu-button"
                aria-expanded="true"
                aria-haspopup="true"
              />
              <div>
                <span className="text-[#F6F7F9] text-[16px] font-normal">{ingestName}</span>
              </div>
            </div>
          </PDFPreviewModal>
        ) : (
          <Link to={ingestFullPath} target="_blank" className="flex items-center gap-4">
            <img
              width="24"
              src={ReadersIcons[readerType]}
              className="rounded-md"
              id="menu-button"
              aria-expanded="true"
              aria-haspopup="true"
            />
            <div>
              <span className="text-[#F6F7F9] text-[16px] font-normal">{ingestName}</span>
            </div>
          </Link>
        )}
      </td>
      <td className="pt-4 pb-3 text-center">
        <span className="text-[#F6F7F9] text-[16px] font-normal">
          {ReaderName[ingest.reader_type]}
        </span>
      </td>
      <td className="pt-4 pb-3 text-center">
        <span className="text-[#F6F7F9] text-[16px] font-normal">{status}</span>
      </td>
      <td className="pt-4 pb-3 text-center">
        <span className="text-[#F6F7F9] text-[16px] font-normal">{creator?.full_name}</span>
      </td>
      <td className="pt-4 pb-3 text-center">
        <span className="text-[#F6F7F9] text-[16px] font-normal">
          {toHumanReadableDate(createdAt)}
        </span>
      </td>
      <td className="pt-4 pb-3 pr-2">
        <div className="flex items-center justify-center gap-2">
          <img
            className="cursor-pointer"
            src="/icons/delete-icon-gray.svg"
            alt="Delete"
            onClick={() => {
              setIsOpen(true);
            }}
          />
          {/* {ReloadAbleReaders[ingest.reader_type] && (
            <img
              className="cursor-pointer w-4"
              src="/icons/sync_icon.png"
              alt="Reread"
              onClick={reIngest}
              title="Re-ingest content"
            />
          )} */}
        </div>
      </td>
      {isOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center h-screen">
            <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center">
              <div className="bg-white w-full max-w-lg mx-auto p-4 rounded-lg shadow-xl">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-semibold text-gray-800">Delete Ingest</h2>
                  <button onClick={closeModal} className="text-red-600 hover:text-red-700">
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="text-gray-700">
                  {/* Modal content goes here */}
                  Do you want to delete <span className="font-bold">{ingestName}</span> ?
                </div>
                <div className="flex justify-end">
                  <button
                    onClick={() => {
                      deleteFile();
                    }}
                    className="bg-blue-500 text-white font-semibold py-2 px-6 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-opacity-50 transition duration-150 ease-in-out"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </tr>
  );
}

export default IngestRow;
