import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';

import { Agents } from '../../apis/Agents';
import { Upload } from '../../apis/Upload';
import { AGENT_TYPES, allowedImageTypes, DEFAULT_LIMIT, PlanType } from 'core/constants';
import { getFilePath } from 'core/helpers';

import './CreateAgent.scss';
import { UserProfile } from 'apis/Profile';
import SpinnerLoader from 'components/Utility/SpinnerLoader';

interface CreateAgentProps {
  closeCreateAgent: () => void;
}

const DEFAULT_VALUE = {
  name: '',
  description: '',
  embeddings: '',
  llm: '',
  image_id: null,
  secret_key_id: null,
  type: AGENT_TYPES[0].value
};
const SECRET_SHOULD_NOT_BE_PROVIDED = 'SHOULD_NOT_BE_PROVIDED';

function CreateAgent({ closeCreateAgent }: CreateAgentProps) {
  const [formPayload, setFormPayload] = useState(DEFAULT_VALUE);
  const [errors, setErrors] = useState<any>({});
  const [uploadProgress, setUploadProgress] = useState(0);

  const [image, setImage] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [configData, setConfigData] = useState<any>({});
  const [secretQueryStatus, setSecretQueryStatus] = useState<string>(SECRET_SHOULD_NOT_BE_PROVIDED);

  const [secretKeys, setSecretKeys] = useState<any[]>([]);
  const [filteredSecretKeys, setFilteredSecretKeys] = useState<any[]>([]);

  const acceptedTypes = useMemo(() => allowedImageTypes.join(','), []);

  const loadSecretKeys = async () => {
    const data = await UserProfile.getUserSecrets({ limit: DEFAULT_LIMIT });
    const keys = data?.results || [];
    setSecretKeys(keys);
  };

  const loadProjectCommonInfo = async () => {
    const data = await Agents.projectCommonInfo();

    if (data) {
      const llmList = data.llms || [];
      const embeddingList = data.embeddings || [];

      const defaultLLM = llmList[0]?.type;
      const defaultEmbedding = embeddingList[0]?.type;

      setConfigData(data);
      setFormPayload({ ...formPayload, llm: defaultLLM, embeddings: defaultEmbedding });
    }
  };

  useEffect(() => {
    loadSecretKeys();
    loadProjectCommonInfo();
  }, []);

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    setUploadProgress(0);
    const data = await Upload.uploadImage(file, setUploadProgress);
    if (data) {
      const imageData = {
        name: data.name,
        id: data.id,
        path: data.file_path
      };

      setImage(imageData);
      setFormPayload({ ...formPayload, image_id: data.id });
    }

    setUploadProgress(0);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setFormPayload({
      ...formPayload,
      [name]: value
    });
  };
  const isValidateForm = () => {
    const { name, llm, embeddings, type, image_id, secret_key_id } = formPayload;
    const newErrors: any = {};

    if (!name.trim()) newErrors.name = 'Name field is required';
    if (!llm) newErrors.llm = 'Model field is required';
    if (!embeddings) newErrors.embeddings = 'Embedding field is required';
    if (!type) newErrors.type = 'Project type field is required';
    if (!image_id) newErrors.image_id = 'Image field is required';
    if (secretQueryStatus == 'REQUIRED' && !secret_key_id) {
      newErrors.secret_key_id = 'Secret key field is required';
    }

    const isValid = isEmpty(newErrors);

    if (!isValid) {
      setErrors(newErrors);
    }

    return isValid;
  };
  const handleFormSubmit = async (e: any) => {
    e.preventDefault();

    if (!isValidateForm()) {
      return;
    }

    setIsSubmitting(true);

    const data = await Agents.createAgents({ ...formPayload });
    if (data) {
      closeCreateAgent();
      window.location.reload();
    }

    setIsSubmitting(false);
  };

  const llms = useMemo(() => {
    let llmList = configData.llms || [];

    if (formPayload.type == 'AGENT') {
      llmList = llmList.filter((obj: any) => obj.is_function_calling == true);
    }

    return llmList;
  }, [configData, formPayload.type]);

  useEffect(() => {
    const llmObj = llms.find((obj) => obj.type == formPayload.llm);
    if (llmObj) {
      setSecretQueryStatus(llmObj?.secret_query_status);
      setFormPayload({ ...formPayload, secret_key_id: null });
      setFilteredSecretKeys([
        ...secretKeys.filter((obj) => obj.key_type == llmObj.secret_key_type)
      ]);
    }
  }, [formPayload.llm]);

  const embeddings = useMemo(() => {
    return configData.embeddings || [];
  }, [configData]);

  const alloweTypes = useMemo(() => {
    if (
      [PlanType.PREMIUM.toString(), PlanType.ENTERPRISE.toString()].includes(configData.plan_type)
    ) {
      return [...AGENT_TYPES];
    }
    return [AGENT_TYPES[0]];
  }, [configData]);

  return (
    <div className="newwark__create-agent-form bg-[#191F28] z-10 rounded-2xl border-[#64BFF3] border-t-2">
      <div className="newwark__create-agent-form-inner">
        <div className="flex items-center justify-between mb-10">
          <h3 className="text-2xl	font-semibold">Create Agent</h3>
          <button
            onClick={() => {
              closeCreateAgent();
            }}
            className="text-[#4E637B] hover:text-[#4E637B]"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>

        <form className="create-agent-form" onSubmit={handleFormSubmit}>
          <div className="form-group mb-6">
            <label className="text-[13px] block font-normal mb-2 leading-4" htmlFor="agent-name">
              Agent Name
            </label>
            <input
              className="form-field w-full border-[1px] border-[#323C48] border-solid rounded-[10px] p-[18px] text-[16px] font-normal bg-transparent outline-none"
              placeholder="Enter a Agent name"
              onChange={handleChange}
              value={formPayload.name}
              id="name"
              name="name"
            />
            {errors?.name && <p className="text-red-600">{errors?.name}</p>}
          </div>
          <div className="form-group mb-6 select-model">
            <label className="text-[13px] block font-normal mb-2 leading-4">Agent Type</label>
            <select
              className="form-field w-full border-[1px] border-[#323C48] border-solid rounded-[10px] p-[18px] text-[16px] font-normal bg-transparent outline-none"
              onChange={handleChange}
              value={formPayload.type}
              id="type"
              name="type"
            >
              {alloweTypes.map((model) => (
                <option key={model.value} className="custom-option" value={model.value}>
                  {model.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group mb-6 select-model">
            <label className="text-[13px] block font-normal mb-2 leading-4">LLM</label>
            <select
              className="form-field w-full border-[1px] border-[#323C48] border-solid rounded-[10px] p-[18px] text-[16px] font-normal bg-transparent outline-none"
              onChange={handleChange}
              value={formPayload.llm}
              id="llm"
              name="llm"
            >
              {llms.map((llmObj: any) => (
                <option key={llmObj.type} className="custom-option" value={llmObj.type}>
                  {llmObj.label}
                </option>
              ))}
            </select>
          </div>
          {secretQueryStatus != SECRET_SHOULD_NOT_BE_PROVIDED && (
            <div className="form-group mb-6 select-model">
              <label className="text-[13px] block font-normal mb-2 leading-4">API Key</label>
              <select
                className="form-field w-full border-[1px] border-[#323C48] border-solid rounded-[10px] p-[18px] text-[16px] font-normal bg-transparent outline-none"
                onChange={handleChange}
                value={formPayload.secret_key_id || ''}
                id="secret_key_id"
                name="secret_key_id"
              >
                <option value={0} className="custom-option">
                  Select API Key
                </option>
                {filteredSecretKeys.map((secretObj: any) => (
                  <option key={secretObj.id} className="custom-option" value={secretObj.id}>
                    {secretObj.name}
                  </option>
                ))}
              </select>
              {errors?.secret_key_id && <p className="text-red-600">{errors?.secret_key_id}</p>}
            </div>
          )}
          <div className="form-group mb-6 select-model">
            <label className="text-[13px] block font-normal mb-2 leading-4">Embedding Model</label>
            <select
              className="form-field w-full border-[1px] border-[#323C48] border-solid rounded-[10px] p-[18px] text-[16px] font-normal bg-transparent outline-none"
              onChange={handleChange}
              value={formPayload.embeddings}
              id="embeddings"
              name="embeddings"
            >
              {embeddings.map((model) => (
                <option key={model.type} className="custom-option" value={model.type}>
                  {model.label}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group mb-6">
            <label className="text-[13px] block font-normal mb-2 leading-4">
              Agent Cover Image
            </label>
            <div className="file-upload-box rounded-[10px] pointer">
              <input
                onChange={(e) => handleUpload(e)}
                className="w-[200px] h-[200px] opacity-0 z-[9999] absolute right-0 left-0 mx-auto"
                multiple
                type="file"
                accept={acceptedTypes}
              />
              {uploadProgress > 0 && (
                <div className="mt-4 mb-4">
                  <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                    <div
                      className="bg-blue-600 h-2.5 rounded-full"
                      style={{ width: `${uploadProgress}%` }}
                    ></div>
                  </div>
                  <p className="mt-2 text-sm text-gray-600">Uploading: {uploadProgress}%</p>
                </div>
              )}
              {!image && (
                <div className="file-upload-box-inner text-center block">
                  <button className="file-upload-btn pointer text-white bg-[#5F6FFF] rounded-[8px] py-[12px] pl-[20px] pr-[16px] inline-flex items-center justify-center gap-[8px] mb-4 text-[14px] font-medium border-[1px] border-[#5F6FFF] border-solid">
                    <img width="10" src="/icons/upload-icon.svg" alt="Upload Icon" />
                    Upload Image
                  </button>
                  <p className="text-sm text-[#8297AE]">
                    Upload a square sized photo for best result & Maximum image size limit should be
                    200 KB (JPG, PNG, JPEG).
                  </p>
                </div>
              )}
              <div className="flex items-center justify-center">
                {image && (
                  <img
                    className="w-[200px] h-[200px]"
                    style={{ objectFit: 'contain' }}
                    src={getFilePath(image.path)}
                    alt=""
                  />
                )}
              </div>
            </div>
            {errors?.image_id && <p className="text-red-600">{errors?.image_id}</p>}
          </div>
          <div className="form-group mb-6">
            <label className="text-[13px] block font-normal mb-2 leading-4">Description</label>
            <textarea
              className="form-field w-full border-[1px] border-[#323C48] border-solid rounded-[10px] p-[18px] text-[16px] font-normal bg-transparent outline-none"
              placeholder="Ex: Marketing Manager"
              onChange={handleChange}
              id="description"
              name="description"
            />
          </div>
          <div className="form-group">
            <SpinnerLoader loading={isSubmitting} icon_size={35}>
              <div className="button-area flex items-center justify-center gap-[16px]">
                <button
                  onClick={() => {
                    closeCreateAgent();
                  }}
                  className="btn btn-item go-back-btn text-base font-semibold text-center p-3 rounded-[12px] basis-1/2 bg-[#323C48] text-[#8297AE]"
                >
                  Go Back
                </button>

                <button
                  type="submit"
                  className="btn btn-item create-btn text-base font-semibold text-center p-3 rounded-[12px] basis-1/2 bg-[#5F6FFF] text-white"
                >
                  Create Agent
                </button>
              </div>
            </SpinnerLoader>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateAgent;
