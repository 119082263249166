import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { RootStateType } from 'store/store';
import { CustomerChatAPI } from 'apis/customerChat';

import ImageAvatar from '../ImageAvatar';
import ChatWidgetsDropdown from 'components/MessageCard/Dropdown/ChatWidgetsDropdown';
import { DEFAULT_LIMIT } from 'core/constants';
import { getUserDisplayName } from 'core/helpers';

function TopBar() {
  const profile = useSelector((state: any) => state.login.profile);
  const [isOpen, setIsOpen] = useState(false);
  const [moreSettings, setMoreSettings] = useState(false);

  const chatWidgets = useSelector((state: RootStateType) => state.customerChat.chatWidgets);

  const getDropdownRef = useRef<HTMLDivElement>(null);
  const getMoreSettingsRef: any = useRef(null);

  useEffect(() => {
    CustomerChatAPI.getChatWidgets({ limit: DEFAULT_LIMIT });
  }, []);

  interface OuterClickHandlerProps {
    ref: React.RefObject<HTMLElement>;
    handler: () => void;
  }

  const handleOutsideClick = ({ ref, handler }: OuterClickHandlerProps) => {
    useEffect(() => {
      const triggerOnClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          handler();
        }
      };

      document.addEventListener('mousedown', triggerOnClickOutside);

      return () => {
        document.removeEventListener('mousedown', triggerOnClickOutside);
      };
    }, [ref, handler]);
  };

  handleOutsideClick({
    ref: getDropdownRef,
    handler: () => setIsOpen(false)
  });

  const handleMoreSettingsClickOutside = (event) => {
    if (getMoreSettingsRef.current && !getMoreSettingsRef.current.contains(event.target)) {
      setMoreSettings(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleMoreSettingsClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleMoreSettingsClickOutside);
    };
  }, []);

  return (
    <div className="fixed top-0 left-[296px] right-0 bg-[#121821] py-[14px] px-8 z-10">
      <div className="flex items-center justify-between">
        <div>
          {/* <h3 className="relative inline-block">
            Messages{' '}
            <span className="absolute top-[-4px] right-[-40px] bg-[#5FBCFF] flex items-center justify-center rounded-[4px] text-[10px] w-[30px] h-[15px]">
              Free
            </span>
          </h3> */}
        </div>
        <div className="text-[#637B94] text-[16px] flex gap-6 items-center">
          <div className="flex gap-6">
            <div className="cursor-pointer">
              {/* <img width="18" src="/message/chat-message-icon.svg" alt="" /> */}
            </div>
            <div className="cursor-pointer relative" ref={getDropdownRef}>
              <div onClick={() => setIsOpen(!isOpen)}>
                <img width="20" src="/message/comment-icon.svg" alt="" />
              </div>
              {isOpen && <ChatWidgetsDropdown chatWidgets={chatWidgets} />}
            </div>

            <div className="cursor-pointer">
              {/* <img width="20" src="/message/message-settings-icon.svg" alt="" /> */}
            </div>
          </div>
          <div
            className="border border-[#222831] flex items-center gap-3 rounded-[52px] py-1 pr-1 pl-4 relative cursor-pointer"
            onClick={() => {
              setMoreSettings(!moreSettings);
            }}
            ref={getMoreSettingsRef}
          >
            {getUserDisplayName(profile?.full_name)}
            <ImageAvatar
              image={profile?.image as any}
              size={32}
              full_name={profile?.full_name}
              fontSize={'[15px]'}
              showCrossIcon={false}
            />
            {moreSettings && (
              <div
                className="absolute  top-full right-0 w-[100%] min-w-[230px] mt-3"
                style={{
                  background: 'var(--Neutral-8, #222831)',
                  borderRadius: '12px',
                  borderBottom: '1px solid rgba(50, 60, 72, 0.40)'
                }}
              >
                <div
                  className="p-[16px]"
                  style={{
                    borderBottom: '1px solid rgba(50, 60, 72, 0.40)'
                  }}
                >
                  <Link
                    to={`/profile-settings`}
                    className="flex gap-4 items-center hover:text-gray-200"
                  >
                    <img className="w-[16px] h-[16px]" src="/icons/add-user.svg" alt="" />
                    <p className="">Account Setting</p>
                  </Link>
                </div>

                <div className="p-[16px] items-center">
                  <a href={`/logout`} className="hover:text-gray-200 flex gap-4 items-center">
                    <img className="w-[16px] h-[16px]" src="/icons/setting.svg" alt="" />
                    <p className="">Logout</p>
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
