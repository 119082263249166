import './ProjectCard.css';
import './ProjectCard.scss';

import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { getAgentTypeText, getFilePath, getUserDisplayName } from '../../core/helpers';
import AddUserModal from '../AddUser/AddUserModal';
import ImageAvatar from 'components/ImageAvatar';

interface ProjectCardProps {
  title: string;
  privacy: string;
  description: string;
  // designation: any;
  id: string;
  agent: any;
  userName: string;
  profileImage: any;
  agentType: any;
}

export const ProjectCard = ({
  title,
  id,
  description,
  // privacy,
  agent,
  userName,
  agentType
}: ProjectCardProps) => {
  const [agentMore, setAgentMore] = useState(false);
  const [isUserAddOpen, setIsUserAddOpen] = useState(false);
  const getMoreAgentRef: any = useRef(null);

  const handleClickOutside = (event) => {
    if (getMoreAgentRef.current && !getMoreAgentRef.current.contains(event.target)) {
      setAgentMore(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="p-6 w-[31.7%] rounded-[8px] border-[1px] border-[#2F3944]">
      <div className="flex items-center justify-between mb-[22px]">
        <div className="image object-cover">
          <img width="32" height="32" src={getFilePath(agent?.image?.file_path)} alt="" />
        </div>
        <div className="relative" ref={getMoreAgentRef}>
          <button
            className="cursor-pointer p-1"
            onClick={() => {
              setAgentMore(!agentMore);
            }}
          >
            <img src="/icons/cog-streamline-core.svg" />
          </button>
          {agentMore && (
            <div
              className="absolute top-6 w-[200px] -right-1.5"
              style={{
                background: 'var(--Neutral-8, #222831)',
                borderRadius: '12px',
                borderBottom: '1px solid rgba(50, 60, 72, 0.40)'
              }}
            >
              <div
                className="p-[16px]"
                style={{
                  borderBottom: '1px solid rgba(50, 60, 72, 0.40)'
                }}
              >
                <div className="flex gap-4 items-center">
                  <img className="w-[16px] h-[16px]" src="/icons/add-user.svg" alt="" />
                  <button onClick={() => setIsUserAddOpen(true)}>
                    <p>Add User</p>
                  </button>
                </div>
              </div>

              <div className="p-[16px] items-center">
                <div className="flex gap-4 items-center">
                  <img className="w-[16px] h-[16px]" src="/icons/setting.svg" alt="" />
                  <Link to={`/agents/${id}/settings`}>
                    <p>Setting</p>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mt-[12px]">
        <div className="">
          <p className="agentName mb-[7px]">{title}</p>
        </div>
        <p
          className="
          mt-[4px] text-sm text-[#8297AE] mb-6 text-ellipsis overflow-hidden display-[-webkit-box] line-clamp-2"
        >
          {description}
        </p>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <ImageAvatar
              image={agent?.owner?.image as any}
              size={20}
              showCrossIcon={false}
              full_name={agent?.owner?.full_name}
              fontSize="15px"
            />
            <div className="text-sm text-[#637B94]">{getUserDisplayName(userName)}</div>
          </div>
          <div className="text-xs	text-[#69e16d] bg-[#22394B] py-[2px] px-3 rounded-[4px]">
            {getAgentTypeText(agentType)}
          </div>
        </div>
      </div>
      <AddUserModal
        projectUid={agent.uid}
        isOpen={isUserAddOpen}
        onClose={() => setIsUserAddOpen(false)}
      />
    </div>
  );
};
