import { useEffect, useRef } from 'react';

import { WS_URL } from 'core/constants';
import eventHandler from './eventHandler';
import { getAccessTokenThatMinValidForNMinutes } from 'apis/api';

const RECONNECT_DELAY = 1000 * 10; // 10 second delay
const TOKEN_PADDING_MINUTES = 5;

const WebSocketComponent = ({ profile }: { profile: any }) => {
  const ws = useRef<WebSocket | null>(null);
  const reconnectTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (profile) {
      console.log({ profile });
      connectWebSocket();

      return () => {
        cleanup();
      };
    }
  }, [profile]);

  const cleanup = () => {
    if (ws.current) {
      ws.current.onclose = null; // Prevent triggering reconnection on manual close
      ws.current.close();
    }
    if (reconnectTimeout.current) {
      clearTimeout(reconnectTimeout.current);
    }
  };

  const handleWsEvent = (event: MessageEvent) => {
    eventHandler(event);
  };

  const connectWebSocket = async () => {
    try {
      const { ephemeralToken } = await getAccessTokenThatMinValidForNMinutes(TOKEN_PADDING_MINUTES);
      ws.current = new WebSocket(`${WS_URL}?token=${ephemeralToken}`);

      ws.current.onopen = () => {
        if (reconnectTimeout.current) {
          clearTimeout(reconnectTimeout.current); // Stop reconnection attempts
          reconnectTimeout.current = null;
        }
      };

      ws.current.onmessage = handleWsEvent;

      ws.current.onclose = () => {
        attemptReconnect(); // Attempt to reconnect on close
      };

      ws.current.onerror = () => {
        ws.current?.close(); // Ensure the socket is closed in case of error
      };
    } catch (error) {
      console.error('Failed to connect WebSocket:', error);
      attemptReconnect();
    }
  };

  const attemptReconnect = () => {
    if (!reconnectTimeout.current) {
      reconnectTimeout.current = setTimeout(() => {
        connectWebSocket();
      }, RECONNECT_DELAY);
    }
  };

  return null;
};

export default WebSocketComponent;
