import { jwtDecode } from 'jwt-decode';

const tokenKey = 'tokens';
export const TOKENS_PER_PAGE = 500;
export const MESSAGE_AVERAGE_TOKEN = 600;

export function setTokens(accessToken: string, refreshToken: string, ephemeralToken: string) {
  const tokenData = JSON.stringify({ accessToken, refreshToken, ephemeralToken });
  localStorage.setItem(tokenKey, tokenData);
}

export function getTokens() {
  const storedValue = localStorage.getItem(tokenKey);

  if (storedValue) {
    const tokens = JSON.parse(storedValue);
    return tokens;
  }
  return {};
}

export function removeTokens() {
  localStorage.removeItem(tokenKey);
}

export function getAccessToken() {
  const { accessToken } = getTokens();
  return accessToken || '';
}

export function getRefreshToken() {
  const { refreshToken } = getTokens();
  return refreshToken || '';
}

export function getEphemeralToken() {
  const { ephemeralToken } = getTokens();
  return ephemeralToken || '';
}

export function updateAccessToken(accessToken: string, ephemeralToken: string) {
  const { refreshToken } = getTokens();

  setTokens(accessToken, refreshToken, ephemeralToken);
}

export function tokenIsExpired(token: string) {
  try {
    const decodedToken = jwtDecode(token);
    const expirationTime = decodedToken.exp ?? 0;

    const currentTime = Date.now() / 1000;

    return expirationTime < currentTime;
  } catch (e) {
    return false;
  }
}

export function willTokenExpiredUnderNMinutes(token: string, minutes: number) {
  const decodedToken = jwtDecode(token);
  const expirationTime = decodedToken.exp ?? 0;

  const paddingInSec = minutes * 60;
  const currentTime = Date.now() / 1000 + paddingInSec;

  return expirationTime < currentTime;
}

export function tokenToPage(token: number | null) {
  if (!token) return 0;

  return Math.round(token / TOKENS_PER_PAGE);
}

export function tokenToMessage(token: number | null) {
  if (!token) return 0;

  return Math.round(token / MESSAGE_AVERAGE_TOKEN);
}
