import './Project.css';

import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Agents } from '../../apis/Agents';
import CreateAgent from '../../components/CreateAgent/CreateAgent';
import { Layout } from '../../components/Layout/Layout';
import { ProjectCard } from '../../components/ProjectCard/ProjectCard';
import { setAgents } from '../../store/reducers/agentsSlice';
import { DEFAULT_LIMIT } from 'core/constants';
import { useDebounce } from 'core/hooks';
import TopBar from 'components/Header/TopBar';
import SpinnerLoader from 'components/Utility/SpinnerLoader';

const Projects = () => {
  const [query, setQuery] = React.useState('');
  const [showCreateAgent, setShowCreateAgent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const agents = useSelector((state: any) => state.agent.agents);
  const getCreateAgentRef: any = useRef(null);

  const dispatch = useDispatch();
  const closeCreateAgent = () => {
    setShowCreateAgent(false);
  };

  const fetchAgents = async (query = '') => {
    const limit = DEFAULT_LIMIT;

    setIsLoading(true);

    const data = await Agents.getAgents({ limit, q: query });
    if (data) {
      dispatch(setAgents(data.results));
    }

    setIsLoading(false);
  };

  const debouncedRequest = useDebounce(300, () => {
    (async () => fetchAgents(query))();
  });

  useEffect(() => {
    fetchAgents();
  }, []);

  const onChange = (e: any) => {
    const value = e.target.value;
    setQuery(value);

    debouncedRequest();
  };

  const handleClickOutside = (event) => {
    if (getCreateAgentRef.current && !getCreateAgentRef.current.contains(event.target)) {
      setShowCreateAgent(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Layout>
      <div className="p-[30px] relative pt-[80px]">
        <TopBar />
        <div ref={getCreateAgentRef}>
          {showCreateAgent && <CreateAgent closeCreateAgent={closeCreateAgent} />}
        </div>
        {/* <div className="flex items-center justify-end mb-6">
          <div>
            <ImageAvatar image={profile?.image as any} size={35} showCrossIcon={false} />
          </div>
        </div> */}
        <img
          className="w-full mb-4 banner-image"
          width="1080"
          height="160"
          src="/agent/banner.png"
          alt="Banner image"
        />
        <div className="flex items-center justify-between mb-4">
          <form className="bg-[#191F28] py-3 px-4 rounded-lg relative w-[88%]">
            <img
              className="absolute left-4 top-1/2 -translate-y-1/2"
              width="20"
              src="/message/search-icon-1.svg"
              alt="Icon"
            />
            <input
              className="bg-transparent border-0 outline-none w-full pl-8"
              type="text"
              placeholder="Search here..."
              onChange={onChange}
            />
          </form>
          <button
            onClick={() => {
              setShowCreateAgent(true);
            }}
            className="CreateBtn agent__create-btn flex items-center gap-2 h-[48px]"
          >
            <img src="/message/plus-icon.svg" />
            Create
          </button>
        </div>
        <div className="mt-[32px]">
          <SpinnerLoader loading={isLoading} icon_size={50}>
            <div className="flex flex-wrap gap-6">
              {agents.map((agent: any) => {
                return (
                  <ProjectCard
                    agent={agent}
                    id={agent.uid}
                    key={agent.uid}
                    title={agent.name}
                    // designation={agent.designation}
                    privacy={agent.is_public ? 'public' : 'private'}
                    description={agent.description}
                    userName={agent.owner.full_name}
                    profileImage={agent.owner.image}
                    agentType={agent.type}
                  />
                );
              })}
            </div>
          </SpinnerLoader>
        </div>
      </div>
    </Layout>
  );
};

export default Projects;
